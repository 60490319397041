import React from 'react';

interface INotFoundComponent {
    children: string;
    title: string;
}

function NotFoundComponent(props: INotFoundComponent) {
    return (<>404</>);
}

export default NotFoundComponent;